import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link'
import { Title, Section, Box, Span, Text } from "../../components/Core";
import results from "../../assets/image/jpeg/best-results.svg";
import work from "../../assets/image/jpeg/treatment-recovery.svg";
import cost from "../../assets/image/jpeg/treatment-cost.svg";
import time from "../../assets/image/jpeg/treatment-time.svg";
import pain from "../../assets/image/jpeg/treatment-pain.svg";
import duration from "../../assets/image/jpeg/treatment-duration.svg";
import Himage from '../../assets/image/jpeg/micro.jpg';
import { device, breakpoints } from "../../utils";
import styled from 'styled-components';


const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;


const Texttitle = styled.p`

font-size:14px;

color:#000;

@media screen and (max-width: 991px)
 {
  font-size:10px;
 
}

`;

const Textdesc = styled.p`

font-size:16px;
color: #1a1a1a;

@media screen and (max-width: 991px)
 {
    font-size:10px;
}

`;


const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;







const Textsmall = styled.p`

font-size:10px;
color: #1a1a1a;

@media screen and (max-width: 991px)
 {
    font-size:8px;
}

`;


const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: url(${Himage}) no-repeat;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:0.1;


`;

const Secti = styled.section`


position: relative;

background-color: #ffffff;


@media screen and (max-width: 767px)
{
  width: 100%;
  margin: 0 auto;
 }

`;

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;



const ContentCard = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box
    bg="#f1f1f1"
    border="1px solid"
    borderColor="border"
    p="20px"
    borderRadius={10}
    className={`d-flex align-items-center ${className}`}
    {...rest}
    css={`
      transition: all 0.3s ease-out;
      &:hover {
        box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
      }
    `}
  >
    <Box
      size={55}
      minWidth={55}
      borderRadius="50%"
      color={color}
    
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background: url(${iconName})  ;
        background-size:cover;
      `}
      mr={3}
    >
      
    </Box>

    <Title variant="card" mb={0}>
      {title}
    </Title>
  </Box>
);

const FatPricePage = () => (
  <>
  <Secti>
 
    <Section py={0}  className="position-relative"
     >
      <Container  className="">
        <Row className=" pt-0 pb-0 justify-content-center">
          
      
      
          <Col
            lg="12"
            md="6"
            className="pr-0 mr-0"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           

 
           <div className="sliderrec3">
  


  <div className="slidesrec3">
    <div className="insideslidesrec3" id="slide-1">
   
      <Texttitle color="black">Fat Dissolving - Chin <span>£290*</span></Texttitle> 
      <Texttitle color="black">Fat Dissolving - 1 Body Area <span>£440*</span></Texttitle> 
      <Texttitle color="black">Fat Dissolving - 2 Body Area <span>£660*</span></Texttitle> 

    </div>

   

    
  </div>

  <Textsmall>* Package Deals Available On The Day</Textsmall>
  <br />
</div>


          </Col>

      
        </Row>
      </Container>
    </Section>
    </Secti>
  </>
);

export default FatPricePage;
